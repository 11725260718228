<template>
  <div>
    <q-resize-observer @resize="onResize" />
    <div v-bind:style="{ 'height': + screenHeight + 'px' }" class="error-page-custom">
      <div class="row justify-center q-pa-none q-ma-none full-height items-center">
        <q-card class="error-code">
          <h4 class="mobile-text-120 tab-font-180 text-center text-white with-bg">Looks like
            <br class="lt-lg">
            <span class="mobile-text-250 tab-font-220 error-text-name capitalize">{{ user.first_name + ' ' + user.last_name + ' '}}</span>
            <br class="lt-lg">broke this page.
          </h4>
          <h1 class="text-center text-white error-text">404</h1>
          <p class="text-center group">
            <q-btn class="q-pa-md" color="negative" big v-if="canGoBack" push @click.native="goBack">
              <q-icon name="keyboard_arrow_left" class="pad-right-half" size="1em"></q-icon>
              Go back
            </q-btn>
            <q-btn class="q-pa-md" big color="negative" push @click.native="$router.push({name:'homePage'})" icon-right="home">
              Go home
            </q-btn>

          </p>

        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'error404',
  data () {
    return {
      screenHeight: 0,
      canGoBack: window.history.length > 1
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    })
  },
  methods: {
    handleWindowResize: function (e) {
      this.$log(this.$options.name, 'Updating Screen Size', window.innerHeight)
      this.screenHeight = window.innerHeight
    },
    onResize (size) {
      this.handleWindowResize()
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="stylus" scoped>
.error-page-custom
  background #000000 url(/statics/testing/page-404.png) no-repeat center center
  background-size cover
  .with-bg
    margin-top 0px
    font-weight 800
.error-text-name
  font-size 5rem
.error-text
    font-weight 800
</style>
